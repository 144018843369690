.firebaseui-container.mdl-card {
  box-shadow: none;
  width: 100%;
  max-width: 800px;
}

#firebaseui_container {
  width: 100%;
}

.firebaseui-card-header {
  visibility: hidden;
}

.mdl-button--raised.mdl-button--colored {
  background: #110066 !important;
}
.mdl-button--primary.mdl-button--primary {
  color: #110066 !important;
}

.mdl-button--raised.firebaseui-idp-button {
  border-radius: 4px;
}

body {
  padding: 0 !important;
}
